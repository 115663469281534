const pageContent = {
  pageTitle: "Rekrutacja",
  pageDescription: {
    title: "Szukasz ciekawej pracy?",
    text: [
      "HyperView Sp. z o.o. to zespół fajnych, profesjonalnych i lekko zakręconych ludzi. W naszym biurze nie ma „open space'ów”, a co tydzień integrujemy się grając w siatkę.",
      "W firmie panuje bardzo dobra atmosfera, oferujemy „otwartą” kuchnię (nie znamy pojęcia lunch time). Ponadto organizujemy spotkania nieformalne - gdzie można wylać żale, podzielić się pomysłem.",
      "Oferujemy elastyczny czas pracy, elastyczne formy zatrudnienia, pracę przy ambitnych projektach, docenianie ciekawych pomysłów, podążanie za aktualnymi trendami: cloud computing, mobile, Smart City."
    ]
  },
  jobOffersTitles: [
    "twoim zadaniem będzie",
    "nasze wymagania",
    "mile widziane",
    "oferujemy"
  ],
  recruitmentInfo: {
    info: "Prosimy o przesyłanie portfolio i informacji o dotychczas zrealizowanych projektach na adres: ",
    mail: "sekretariat@hyperview.pl",
    clausule1: "Prosimy o opatrzenie CV klauzulą: ",
    clausule2: "Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb niezbędnych do realizacji procesu tej oraz przyszłych rekrutacji (zgodnie z ustawą z dnia 10 maja 2018 roku o ochronie danych osobowych (Dz. Ustaw z 2018, poz. 1000) oraz zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO)).",
  }
};

export { pageContent };