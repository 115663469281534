import React from "react";

import { CompasBreakLine } from "utils";
import { pageContent } from "staticContent/more/recruitment";

const Recruitment = (data) => {

  const { pageTitle, pageDescription, jobOffersTitles, recruitmentInfo } = pageContent;

  const heroDescriptionPart = pageDescription.text.map((paragraph, index) => (
    <p key={index} className="recruitment__text">
      {paragraph}
    </p>
  ));

  const jobOffersPart = data.allWpOfertaPracy.nodes.map((node, index) => {

    const { offerName, yourTasks, ourExpectations, niceToHave, weOffers } = node.ofertaPracy;

    const list = [yourTasks, ourExpectations, niceToHave, weOffers];

    return (
      <div className="recruitment__job-block" key={index}>
        {index > 0 && <CompasBreakLine />}
        <div className="recruitment-offer">

          <div>
            <h3 className="recruitment-offer__job-title">{offerName}</h3>
            <div className="recruitment-offer__pic"></div>
          </div>

          <div>
            {list.map((item, index) => (
              <div key={index} className="recruitment-offer__block">
                <h4 className="recruitment-offer__subtitle">{jobOffersTitles[index]}</h4>
                <div dangerouslySetInnerHTML={{ __html: item }} />
              </div>
            ))}
          </div>

        </div>
      </div>
    )
  });

  return (
    <>
      <section className="section hero recruitment">
        <h1 className="hero__banner-text">{pageTitle}</h1>
        <div className="hero__cards-wrapper recruitment__hero">
          <div className="hero__cards-bcg recruitment__hero-bcg"></div>
          <div className="recruitment__hero-wrapper">
            <h2 className="recruitment__title">{pageDescription.title}</h2>
            {heroDescriptionPart}
          </div>
        </div>
      </section>
      <section className="section">
        {jobOffersPart}
        <div className="recruitment__contact-detials">
          <p className="recruitment__text">
            {recruitmentInfo.info}
            <strong>
              <a href={`mailto:${recruitmentInfo.mail}`} target="_top">
                {recruitmentInfo.mail}
              </a>
            </strong>
          </p>
          <p className="recruitment__text">
            <strong>{recruitmentInfo.clausule1}</strong>
            {recruitmentInfo.clausule2}
          </p>
        </div>
      </section>
    </>
  )
}

export default Recruitment;