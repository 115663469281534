import React from "react";
import { graphql } from "gatsby";

import Layout from "utils/Layout";
import Recruitment from "components/Recruitment";
import { pageContent } from "staticContent/more/recruitment";

import "styles/components/recruitment.scss";

const IndexPage = ({ data }) => (
  <Layout subTitle={pageContent.pageTitle}>
    <Recruitment {...data} />
  </Layout>
)

export default IndexPage;

export const query = graphql`
  {
    allWpOfertaPracy(sort: {fields: modified, order: DESC}) {
      nodes {
        ofertaPracy {
          offerName
          yourTasks
          ourExpectations
          niceToHave
          weOffers
        }
      }
    }
  }
`;